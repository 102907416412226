import * as React from 'react'

import {
  HorizontalAlignment,
  useSiteConfig,
  useTheme,
} from '@thg-commerce/enterprise-core'
import { CategoryItemCardPicture } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/CategoryItemCardPicture'
import { GlobalSixItemCategoriesPicture } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/GlobalSixItemCategoriesPicture'
import {
  MultiCategoryCardSet,
  MultiCategoryCardSetV2,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  CategorySet,
  CategorySetItemProps,
  CategorySetProps,
} from '@thg-commerce/enterprise-widget-category-set'
import {
  MarginInterface,
  PaddingInterface,
} from '@thg-commerce/enterprise-widget-editorial/theme'
import { WidgetBackgroundColour } from '@thg-commerce/gravity-elements'
import {
  margin,
  mq,
  padding,
  spacing,
  styled,
} from '@thg-commerce/gravity-theme'

import { BaseWidgetProps } from '../types'

interface MultiCategoryCardSetV2WithChildren
  extends Omit<MultiCategoryCardSetV2, 'banners'> {
  banners: Omit<CategoryItemCardPicture, 'id' | 'query'>[]
}

interface MultiCategoryCardSetWithChildren
  extends Omit<MultiCategoryCardSet, 'banners' | 'backgroundColour'> {
  banners: Omit<CategoryItemCardPicture, 'id' | 'query'>[]
  backgroundColour?: string
}

export type CategorySetWidgetRendererProps = BaseWidgetProps &
  (
    | Omit<GlobalSixItemCategoriesPicture, 'id' | 'query'>
    | Omit<MultiCategoryCardSetWithChildren, 'id' | 'query'>
    | Omit<MultiCategoryCardSetV2WithChildren, 'id' | 'query'>
  )

const StyledCategorySet = styled(CategorySet)<{
  gutterPadding: PaddingInterface
  gutterMargin: MarginInterface
  styleOverride?: {
    margin: number
    width?: string
  }
}>`
  max-width: ${(props) =>
    props.styleOverride?.width ?? props.theme.site.siteWidth};
  margin: ${spacing(1)} ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: 0 auto;
    padding: ${spacing(2)} 0;
  }

  ${(props) => padding(props.gutterPadding)}

  ${(props) =>
    props.styleOverride ? `margin: 0;` : margin(props.gutterMargin)}
`

const validAlignment = (alignment?: string | undefined | null) => {
  if (alignment === 'centre' || !alignment) {
    return 'center' as HorizontalAlignment
  }
  return alignment as HorizontalAlignment
}

export const CategorySetWidgetRenderer = (
  props: CategorySetWidgetRendererProps,
) => {
  const theme = useTheme()
  const { enableStyleOverrideSixItemCategoryWidget } = useSiteConfig()

  if (props.__typename === 'GlobalSixItemCategories') {
    const categorySetProps: CategorySetProps = {
      ...(props.title && {
        titleProps: {
          title: props.title,
        },
      }),
      horizontalAlignment: validAlignment(props.itemTitleAlign),
      gutterPadding: theme.widget.categorySet.sixItemCategory.gutterPadding,
      container: theme.widget.categorySet.sixItemCategory.container,
      gutterMargin: theme.widget.categorySet.sixItemCategory.gutterMargin,
      styleOverride: enableStyleOverrideSixItemCategoryWidget
        ? {
            margin: 1,
          }
        : undefined,
      items: [
        {
          ...(props.itemOneTitle && {
            titleProps: {
              title: props.itemOneTitle,
            },
          }),
          picture: props.pictures.pictureOne,
          buttonContent: props.itemOneCTA || undefined,
          buttonLink: props.itemOneLink || undefined,
          horizontalAlignment: validAlignment(props.itemTitleAlign),
          contentGutterPadding:
            theme.widget.categorySet.sixItemCategory.contentGutterPadding,
        },
        {
          ...(props.itemTwoTitle && {
            titleProps: {
              title: props.itemTwoTitle,
            },
          }),
          picture: props.pictures.pictureTwo,
          buttonContent: props.itemTwoCTA || undefined,
          buttonLink: props.itemTwoLink || undefined,
          horizontalAlignment: validAlignment(props.itemTitleAlign),
          contentGutterPadding:
            theme.widget.categorySet.sixItemCategory.contentGutterPadding,
        },
        {
          ...(props.itemThreeTitle && {
            titleProps: {
              title: props.itemThreeTitle,
            },
          }),
          picture: props.pictures.pictureThree,
          buttonContent: props.itemThreeCTA || undefined,
          buttonLink: props.itemThreeLink || undefined,
          horizontalAlignment: validAlignment(props.itemTitleAlign),
          contentGutterPadding:
            theme.widget.categorySet.sixItemCategory.contentGutterPadding,
        },
        {
          ...(props.itemFourTitle && {
            titleProps: {
              title: props.itemFourTitle,
            },
          }),
          picture: props.pictures.pictureFour,
          buttonContent: props.itemFourCTA || undefined,
          buttonLink: props.itemFourLink || undefined,
          horizontalAlignment: validAlignment(props.itemTitleAlign),
          contentGutterPadding:
            theme.widget.categorySet.sixItemCategory.contentGutterPadding,
        },
        {
          ...(props.itemFiveTitle && {
            titleProps: {
              title: props.itemFiveTitle,
            },
          }),
          picture: props.pictures.pictureFive,
          buttonContent: props.itemFiveCTA || undefined,
          buttonLink: props.itemFiveLink || undefined,
          horizontalAlignment: validAlignment(props.itemTitleAlign),
          contentGutterPadding:
            theme.widget.categorySet.sixItemCategory.contentGutterPadding,
        },
        {
          ...(props.itemSixTitle && {
            titleProps: {
              title: props.itemSixTitle,
            },
          }),
          picture: props.pictures.pictureSix,
          buttonContent: props.itemSixCTA || undefined,
          buttonLink: props.itemSixLink || undefined,
          horizontalAlignment: validAlignment(props.itemTitleAlign),
          contentGutterPadding:
            theme.widget.categorySet.sixItemCategory.contentGutterPadding,
        },
      ],
    }
    return <StyledCategorySet {...categorySetProps} />
  }
  if (
    props.__typename === 'multiCategoryCardSet' &&
    props.banners.length >= 2
  ) {
    const categoryItemsMap = props.banners.map((item) => {
      return {
        ...(item.itemTitle && {
          titleProps: {
            title: item.itemTitle,
          },
          font: {
            style: theme.widget.categorySet.multiCategory.item.title.font.style,
          },
        }),
        content: item.itemDescription,
        horizontalAlignment: validAlignment(item.textAlignment),
        picture: item.picture,
        buttonContent: item.itemCTA || undefined,
        buttonLink: item.itemUrl || undefined,
        contentGutterPadding:
          theme.widget.categorySet.multiCategory.contentGutterPadding,
        emphasis:
          theme.widget.categorySet.multiCategory.item.cta.emphasis || 'low',
        ctaPadding: theme.widget.categorySet.multiCategory.item.cta.padding,
        anchorWidth:
          theme.widget.categorySet.multiCategory?.anchorWidth || 'auto',
      } as CategorySetItemProps
    })
    const categorySetProps: CategorySetProps = {
      horizontalAlignment: 'center',
      gutterPadding: theme.widget.categorySet.multiCategory.gutterPadding,
      container: theme.widget.categorySet.multiCategory.container,
      gutterMargin: theme.widget.categorySet.multiCategory.gutterMargin,
      items: [
        categoryItemsMap[0],
        categoryItemsMap[1],
        ...categoryItemsMap.splice(2),
      ],
    }

    return (
      <WidgetBackgroundColour backgroundColour={props.backgroundColour}>
        <StyledCategorySet
          {...categorySetProps}
          titleProps={
            props.widgetTitle ? { title: props.widgetTitle } : undefined
          }
          subtitleProps={
            props.widgetSubtitle
              ? { subtitle: props.widgetSubtitle }
              : undefined
          }
          numberOfColumns={props.columnsNumber || undefined}
        />
      </WidgetBackgroundColour>
    )
  }
  if (props.__typename === 'multiCategoryCardSetV2') {
    const titleTheme = props.theme
    const items: CategorySetItemProps[] = props?.banners?.map((banner) => ({
      ...(banner.itemTitle && {
        titleProps: {
          title: banner.itemTitle,
          titleColour:
            titleTheme === 'light' || titleTheme === 'dark'
              ? titleTheme
              : undefined,
        },
      }),
      picture: {
        sources: [
          {
            sourceSet: banner.itemImagePath,
          },
        ],
        default: banner.itemImagePath,
        alt: banner.altTextitem!,
      },
      horizontalAlignment: validAlignment(banner.textAlignment),
      contentGutterPadding:
        theme.widget.categorySet.multiCategoryCardSetV2.contentGutterPadding,
      buttonLink: banner.itemUrl || undefined,
      font: theme.widget.categorySet.multiCategoryCardSetV2.font,
    }))

    const categorySetProps: CategorySetProps = {
      items: [items[0], items[1], ...items.slice(2)],
      ...(props.widgetTitle && {
        titleProps: {
          title: props.widgetTitle,
          titleColour:
            titleTheme === 'light' || titleTheme === 'dark'
              ? titleTheme
              : undefined,
        },
      }),
      gutterPadding:
        theme.widget.categorySet.multiCategoryCardSetV2.gutterPadding,
      container: theme.widget.categorySet.multiCategoryCardSetV2.container,
      gutterMargin:
        theme.widget.categorySet.multiCategoryCardSetV2.gutterMargin,
      styleOverride:
        theme.widget.categorySet.multiCategoryCardSetV2.flexSpacing !==
        undefined
          ? {
              margin:
                theme.widget.categorySet.multiCategoryCardSetV2.flexSpacing,
              width: theme.widget.categorySet.multiCategoryCardSetV2.width,
            }
          : undefined,
    }

    return (
      <WidgetBackgroundColour
        backgroundColour={props.backgroundColour || undefined}
        margin={theme.widget.categorySet.multiCategoryCardSetV2.containerMargin}
      >
        <StyledCategorySet
          {...categorySetProps}
          numberOfColumns={props.columnsNumber || undefined}
          mobileColumns={props.mobileColumns || undefined}
        />
      </WidgetBackgroundColour>
    )
  }
  return null
}
